export class ConsumerRequest {
  pkId?: number; 
  requestID ?: string;
  firstName ?: string;
  lastName ?: string;
  email ?: string;
  relationship ?: string;
  address ?: string;
  addressLine ?: string;
  city ?: string;
  postalCode ?: number;
  state ?: string;
  country ?: string;
  phone ?: string;
  source ?: string;
  ivantiTN ?: string;
  deleteRequest ?: boolean;
  viewRequest ?: boolean;
  pdf ?: string;
  requestedDate ?: Date;
  responseDate ?: Date;
  piiSent ?: boolean;
  piiDeleted ?: boolean;
  requestType ?: string;
  verificationCode ?: string;
  isConfirmed?: boolean;
  officeUse?: string;
  righttoKnow ?: string;
  denialRequest ?: string;
  recordDeleted?: boolean;
  confirmedDate?: Date;
}
