import { Component, OnInit } from '@angular/core';
import { LoginService } from '../Service/login.service';
import { ConsumerRequest } from '../Model/ConsumerRequest';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { FileuploadService } from '../Service/fileupload.service';
import { HttpEventType, HttpEvent, HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-backend-form',
  templateUrl: './backend-form.component.html',
  styleUrls: ['./backend-form.component.css']
})
export class BackendFormComponent implements OnInit {
  consumer: ConsumerRequest = new ConsumerRequest();
  RequestForm: FormGroup;
  backendForm: FormGroup;
  searchSubmit: boolean = false;
  RequestID: string = '';
  showBackedForm: boolean = false;
  submitted: boolean = false;
  showDownloadLink: boolean = false;
  relations: any = [];
  list: any = [];
  newArray: Array<any> = [];
  emailFormArray: FormArray;
  public progress: number;
  public message: string;
  loading: boolean = false;
  display: string = 'none';
  countyObj: any = {};
  stateObj: any = [];
  recordMsg: string = "";
  errorMsg: string = "";
  responseSent = false;
  showResultSentDate = false;

  constructor(private formBuilder: FormBuilder,
    private loginService: LoginService,
    private fileuploadService: FileuploadService,
    private httpClient: HttpClient,
    private SpinnerService: NgxSpinnerService) { }

  ngOnInit() {

    this.backendForm = this.formBuilder.group({
      'requestID': new FormControl('', Validators.compose([Validators.required]))
    });

    this.RequestForm = this.formBuilder.group({
      'firstName': new FormControl('', Validators.compose([Validators.required])),
      'lastName': new FormControl('', Validators.compose([Validators.required])),
      'email': ['', [Validators.required, Validators.email]],
      'address': new FormControl('', Validators.compose([Validators.required])),
      'addressLine': [null],
      'city': new FormControl('', Validators.compose([Validators.required])),
      'postalCode': new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[0-9]{5}$')])),
      'country': new FormControl('', Validators.compose([Validators.required])),
      'state': new FormControl('', Validators.compose([Validators.required])),
      'phone': new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$')])),
      'relationship': [null],
      'requestType': new FormControl('', Validators.compose([Validators.required])),
      'ivantiTN': new FormControl('', Validators.compose([Validators.required])),
      'profile': new FormControl('', Validators.compose([Validators.required])),
      'denialRequest': [null],
      'righttoKnow': [null],
      'recordDeleted': [null],
      'officeUse' : [null],
      rel: this.formBuilder.array([])
    });
    this.emailFormArray = <FormArray>this.RequestForm.controls.rel;

    this.httpClient.get("assets/gistfile1.json").subscribe(data => {
      this.countyObj = data;
    });
  }

  get b() { return this.backendForm.controls; }
  get f() { return this.RequestForm.controls; }

  uploadFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('profile', file);
      formData.append('requestID', this.RequestID);

      this.fileuploadService.upload(formData)
        .subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              this.progress = Math.round(event.loaded / event.total * 100);
              break;
            case HttpEventType.Response:
              setTimeout(() => {
                this.progress = 0;
                this.consumer.pdf = file.name;
                this.showDownloadLink = true;
              }, 2500);

          }
        });
    }
  }


  _keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();

    }
  }

  onSubmit() {
    this.searchSubmit = true;
    this.showBackedForm = false;
    this.responseSent = false;
    if (!this.backendForm.valid) {
      return;
    }

    (<FormArray>this.RequestForm.controls.rel).clear();
    this.consumer.relationship = "";

    this.SpinnerService.show();
    this.loginService.SearchByRequestID(this.RequestID).subscribe(
      (res: ConsumerRequest) => {
        this.consumer = res;
        if (this.consumer.pkId > 0) {
          this.pageConfiguration();
          this.SpinnerService.hide();
        } else {
          this.SpinnerService.hide();
          this.recordMsg = "No record found. Please try again.";
        }
      }, err => {
        this.SpinnerService.hide();
      });
  }

  pageConfiguration() {
    this.reinitializeArray();
    this.relations = this.list;
    if (this.consumer.relationship == null) {
      this.consumer.relationship = "[]";
    }
    this.CheckReponseSent();
    this.AddSelectedRelations(JSON.parse(this.consumer.relationship));
    this.getSelectedItems(JSON.parse(this.consumer.relationship));
    this.onCountrySelected(this.consumer.country);
    this.showBackedForm = true;
    this.searchSubmit = false;
    this.recordMsg = "";
    if (this.consumer.pdf != null) {
      if (this.consumer.pdf.indexOf(".pdf")) {
        this.showDownloadLink = true;
      }
    }
    if (this.consumer.piiSent) {
      this.showResultSentDate = true;
    } else if (this.consumer.piiDeleted) {
      this.showResultSentDate = true;
    } else {
      this.showResultSentDate = false;
    }
  }

  CheckReponseSent() {
    if (this.consumer.piiSent) {
      this.responseSent = true;
    }
    if (this.consumer.piiDeleted) {
      this.responseSent = true;
    }
  }

  reinitializeArray() {
    this.list = [{ "name": "SP+ Monthly Parking Customer" }, { "name": "SP+ Transient Parking Customer" }, { "name": "Bags Customer" }, { "name": "Parking.com Customer" }, { "name": "Former Employee" }, { "name": "Current Employee" }];//, { "name": "None of the Above" }];
    this.relations = [];
    this.newArray = [];
  }



  onFormSubmit() {
    event.preventDefault();
    this.submitted = true;
    this.errorMsg = "";
    // stop here if form is invalid
    if (!this.RequestForm.valid) {
      this.loading = false;
      return;
    }
    this.SpinnerService.show();
    this.loginService.UpdateRequest(this.consumer).subscribe(
      (res: any) => {
        this.loading = false;
        this.Reset();
        this.display = 'block';
        this.SpinnerService.hide();
      }, err => {
        this.errorMsg = "Something went wrong. Please try again."
        this.SpinnerService.hide();
      });
  }

  filterdata(item: string) {
    for (let i = 0; i < this.countyObj.countries.length; i++) {
      if (this.countyObj.countries[i].country === item) {
        this.stateObj = this.countyObj.countries[i].states;
      }
    }
  }

  onCountrySelected(item: string) {
    this.filterdata(item);
    //this.consumer.country = item;
  }

  Reset() {
    this.submitted = false;
    this.RequestForm.reset();
    this.errorMsg = "";
  }

  onChange(item: string, isChecked: boolean) {
    this.emailFormArray = <FormArray>this.RequestForm.controls.rel;

    if (isChecked) {
      this.emailFormArray.push(new FormControl(item));
    } else {
      this.emailFormArray.removeAt(this.emailFormArray.controls.findIndex(x => x.value === item));
    }
    this.consumer.relationship = JSON.stringify(this.emailFormArray.value);
  }

  getSelectedItems(item: Array<any>) {
    for (var i = 0; i < this.relations.length; i++) {
      var ismatch = false;
      for (var j = 0; j < item.length; j++) {

        if (this.relations[i].name === item[j]) {
          ismatch = true;
          this.relations[i].checked = true;
          this.newArray.push(this.relations[i]);
          break;
        }
      }
      if (!ismatch) {
        this.relations[i].checked = false;
        this.newArray.push(this.relations[i]);
      }
    }
  }

  AddSelectedRelations(item: Array<any>) {
    for (var j = 0; j < item.length; j++) {
      this.emailFormArray.push(new FormControl(item[j]));
    }
  }

  downloadDoc() {
    this.fileuploadService.downloadDoc(this.consumer.requestID).subscribe(data => this.downloadFile(data)),
      error => console.log('Error downloading the file.'),
      () => console.info('OK');
  }

  downloadFile(data: any) {
    const blob = new Blob([data], { type: 'application/pdf' });
    importedSaveAs(data, this.consumer.pdf);
  }

  onCloseHandled() {
    this.display = 'none';
    this.relations = [];
    this.showBackedForm = false;
    this.showDownloadLink = false;
    this.showResultSentDate = false;
    (<FormArray>this.RequestForm.controls.rel).clear();
  }
}
