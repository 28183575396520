import { Component, OnInit } from '@angular/core';
import { LoginService } from '../Service/login.service';
import { ConsumerRequest } from '../Model/ConsumerRequest';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-confirm-request',
  templateUrl: './confirm-request.component.html',
  styleUrls: ['./confirm-request.component.css']
})
export class ConfirmRequestComponent implements OnInit {
  consumer: ConsumerRequest = new ConsumerRequest();
  message: string;
  showMessage: boolean = false;
  constructor(private service: LoginService, private activatedRoute: ActivatedRoute, private SpinnerService: NgxSpinnerService) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.consumer.email = params['email'];
      this.consumer.verificationCode = params['verificationCode'];
      this.consumer.pkId = +params['request'];
    });

    this.SpinnerService.show();
    this.service.IsEmailVerified(this.consumer).
      pipe(first())
      .subscribe(
        data => {
          this.message = data.msg;
          this.showMessage = true;
          this.SpinnerService.hide()
        },
        error => {
          this.SpinnerService.hide()
          console.log(error);
        });
  }

}
