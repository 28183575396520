import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { ConsumerRequest } from '../Model/ConsumerRequest';
import { catchError, retry, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private url: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.url = baseUrl;
  }

  public AddRequest(consumer: ConsumerRequest): Observable<ConsumerRequest> {
    let apiUrl = this.url + 'api/admin/v1/consumer/';
    return this.http.post<ConsumerRequest>(apiUrl + 'AddRequest', consumer)
      .pipe(
        catchError(this.handleError)
      );
  }

  public UpdateRequest(consumer: ConsumerRequest): Observable<ConsumerRequest> {
    let apiUrl = this.url + 'api/admin/v1/consumer/';
    return this.http.post<ConsumerRequest>(apiUrl + 'update', consumer)
      .pipe(
        catchError(this.handleError)
      );
  }

  public IsEmailVerified(consumer: ConsumerRequest): Observable<any> {   
    let apiUrl = this.url + 'api/admin/v1/consumer/';
    return this.http.post<any>(apiUrl + 'IsEmailverified', consumer)
      .pipe(
      catchError(this.handleError)
    );
  }

  public SearchByRequestID(requestID: string): Observable<any> {
    let apiUrl = this.url + 'api/admin/v1/consumer/';
    let params = new HttpParams().set('requestID', requestID);

    return this.http.get<any>(apiUrl + 'SearchByRequestID', { params: params })
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}

