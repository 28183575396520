import { Component, OnInit, VERSION } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MustMatch } from '../_helpers/mustwatch';
import { LoginService } from '../Service/login.service';
import { ConsumerRequest } from '../Model/ConsumerRequest';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-consumer-request',
  templateUrl: './consumer-request.component.html',
  styleUrls: ['./consumer-request.component.css']
})
export class ConsumerRequestComponent implements OnInit {
  consumer: ConsumerRequest = new ConsumerRequest();
  RequestForm: FormGroup;
  public version = VERSION.full;
  submitted: boolean = false;
  loading = false;
  countyObj: any = {};
  display = 'none';
  stateObj: any = [];
  errorMsg: string = "";
  //zipPattern = '/^\d{5}$/';
  relations: any = ["SP+ Monthly Parking Customer", "SP+ Transient Parking Customer", "Bags Customer", "Parking.com Customer", "Former Employee", "Current Employee"];//, "None of the Above"];

  constructor(private formBuilder: FormBuilder, private httpClient: HttpClient, private service: LoginService, private SpinnerService: NgxSpinnerService) { }

  ngOnInit() {
    this.consumer.requestType = 'view';
    this.RequestForm = this.formBuilder.group({
      'firstName': new FormControl('', Validators.compose([Validators.required])),
      'lastName': new FormControl('', Validators.compose([Validators.required])),
      'email': ['', [Validators.required, Validators.email]],
      'confirmEmail': ['', [Validators.required, Validators.email]],
      'address': new FormControl('', Validators.compose([Validators.required])),
      'addressLine': [null],
      'city': new FormControl('', Validators.compose([Validators.required])),
      'postalCode': new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[0-9]{5}$')])),
      'country': new FormControl('', Validators.compose([Validators.required])),
      'state': new FormControl('', Validators.compose([Validators.required])),
      'phone': new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$')])),
      'relationship': [null],
      'requestType': new FormControl('', Validators.compose([Validators.required])),
      'officeUse': [null],
      'recaptchaReactive': new FormControl(null, Validators.required),
      rel: this.formBuilder.array([])
    }, {
      validator: MustMatch('email', 'confirmEmail')
    });

    this.httpClient.get("assets/gistfile1.json").subscribe(data => {
      this.countyObj = data;
    });
  }

  get f() { return this.RequestForm.controls; }

  onSubmit() {
    event.preventDefault();
    this.submitted = true;
    this.errorMsg = "";
    if (!this.RequestForm.valid) {
      this.loading = false;
      return;
    }
    this.SpinnerService.show();
    this.service.AddRequest(this.consumer).subscribe(
      (res: any) => {
        this.loading = false;
        this.SpinnerService.hide();
        this.Reset();
        this.display = 'block';
      }, err => {
        this.SpinnerService.hide();
        this.errorMsg = "Something went wrong. Please try again."
        console.log(err);
      });
  }

  filterdata(item: string) {
    for (let i = 0; i < this.countyObj.countries.length; i++) {
      if (this.countyObj.countries[i].country === item) {
        this.stateObj = this.countyObj.countries[i].states;
      }
    }
  }

  _keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();

    }
  }

  onCountrySelected(item: string) {
    this.filterdata(item);
    this.consumer.country = item;
  }

  onStateSelected(item: string) {
    this.consumer.state = item;
  }

  onChange(item: string, isChecked: boolean) {
    const emailFormArray = <FormArray>this.RequestForm.controls.rel;

    if (isChecked) {
      emailFormArray.push(new FormControl(item));
    } else {
      emailFormArray.removeAt(emailFormArray.controls.findIndex(x => x.value === item));
    }
    this.consumer.relationship = JSON.stringify(emailFormArray.value);
  }

  Reset() {
    this.submitted = false;
    this.RequestForm.reset();
  }

  onCloseHandled() {
    this.display = 'none';
    this.consumer.requestType = 'view';
    this.errorMsg = "";
    (<FormArray>this.RequestForm.controls.rel).clear();
  }
}
