
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginDto } from '../Model/user';
import { NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../Service/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login-user',
  templateUrl: './login-user.component.html',
  styleUrls: ['./login-user.component.css']
})
export class LoginUserComponent implements OnInit {
  loginDto: LoginDto = {};
  loading = false;
  submitted = false;
  returnUrl: string;
  errorResponse: string;
  IsLoginError: boolean = false;

  constructor(private _router: Router,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private SpinnerService: NgxSpinnerService) {  
  }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  onSubmit(loginForm: NgForm) {
    this.submitted = true;
    this.IsLoginError = false;
    if (!loginForm.valid) { return; }

    //this.loading = true;
    this.SpinnerService.show();
    this.authenticationService.login(this.loginDto)
      .pipe(first())
      .subscribe(
        data => {
          var user = data;
          if (user != null) {
            this.SpinnerService.hide();
           // this.router.navigate([this.returnUrl]);
            this.router.navigate(['webform']);
          } else {
           // this.loading = false;
            this.SpinnerService.hide();
            this.IsLoginError = true;
            this.errorResponse = 'Invalid username or password. Please try again.'
          }          
        },
        error => {
          this.IsLoginError = true;
          this.SpinnerService.hide();
          this.errorResponse = 'Invalid username or password. Please try again.'
          this.loading = false;
        });
  }

  loggedIn() {
    console.log("hello  ")
    this._router.navigate(['user-form']);
  }
}
