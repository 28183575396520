import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpRequest, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileuploadService {

  constructor(private httpClient : HttpClient) { }

  upload(formData) {
    return this.httpClient.post<any>('api/admin/v1/fileupload/fileupload', formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      catchError(this.handleError)
    );
  }

  downloadDoc(requestID: string): Observable<Blob> {
    let params = new HttpParams().set('requestID', requestID);

    return this.httpClient.get('api/admin/v1/fileupload/Downloadreport', { params : params, responseType: 'blob' })
      .pipe(
       // catchError(this.handleError)
      );
  }

  //private handleError(error: HttpErrorResponse) {
  //  if (error.error instanceof ErrorEvent) {
  //    console.error('An error occurred:', error.error.message);
  //  } else {
  //    console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
  //  }
  //  return throwError('Something bad happened. Please try again later.');
  //}

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
