import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { LoginUserComponent } from './login-user/login-user.component';
import { ConsumerRequestComponent } from './consumer-request/consumer-request.component';
import { AuthGuard } from './auth.guard';
import { BackendFormComponent } from './backend-form/backend-form.component';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'node_modules/ng-recaptcha';
import { ConfirmRequestComponent } from './confirm-request/confirm-request.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { FormDirective } from './_helpers/FormDirective';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    LoginUserComponent,
    ConsumerRequestComponent,
    BackendFormComponent,
    ConfirmRequestComponent,
    FormDirective
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule, ReactiveFormsModule,   
    RecaptchaModule,
    RecaptchaFormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    RouterModule.forRoot([
      { path: 'login', component: LoginUserComponent},
      { path: 'webform', component: BackendFormComponent, canActivate: [AuthGuard] },
      { path: '', redirectTo: '/requestorform', pathMatch: 'full' },
      { path: 'requestorform', component: ConsumerRequestComponent},
      { path: 'confirmrequest', component: ConfirmRequestComponent },      
      //{ path: '**', component: PageNotFoundComponent }
    ])
  ],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: '6Ld43OkUAAAAAOL0YENR6hCWCXoFsQs8BXd30IPh',
    } as RecaptchaSettings,
  }
    //, { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
